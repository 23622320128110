import $ from 'jquery'
// import slick from local copy due to 1.9.0 being unlisted
import "../slick/slick.js"

export default () => {
  document.querySelectorAll('.block-wrapper .slick-slider').forEach(item => {
    initBlockSlickSlider(item.closest('.block-wrapper').id)
  })
  initPageHeaderSlider()
}

// Slicker slider function to init slideshows for gallery layouts
export function initBlockSlickSlider(blockId) {
  if (document.querySelector("#slick-slider-nav-" + blockId)) {
    $("#slider-" + blockId).not('.slick-initialized').slick({
      infinite: true,
      autoplay: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      rows: 0, // fix for extra div https://github.com/kenwheeler/slick/issues/3207
      arrows: false,
      pauseOnHover:false,
      asNavFor: '#slick-slider-nav-' + blockId
    });

    $("#slick-slider-nav-" + blockId).not('.slick-initialized').slick({
      asNavFor: "#slider-" + blockId,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><span class="material-icons">keyboard_arrow_right</span></button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><span class="material-icons">keyboard_arrow_left</span></button>',
      rows: 0, // fix for extra div https://github.com/kenwheeler/slick/issues/3207
      focusOnSelect: true,
      variableWidth: true,
      centerMode: true,
    });
  } else {
    $("#slider-" + blockId).not('.slick-initialized').slick({
      infinite: true,
      autoplay: true,
      speed: 500,
      fade: true,
      pauseOnHover:false,
      cssEase: 'linear',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><span class="material-icons">keyboard_arrow_right</span></button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><span class="material-icons">keyboard_arrow_left</span></button>',
      rows: 0, // fix for extra div https://github.com/kenwheeler/slick/issues/3207
      arrows: true
    });
  }
}

export function initPageHeaderSlider() {
  if (document.querySelector('#page-header-slideshow')) {
    const slideshow = document.querySelector('#page-header-slideshow'),
    autoplay = slideshow.dataset.autoplay === 'true',
    speed = Number(slideshow.dataset.speed),
    arrows = slideshow.dataset.arrows === 'true',
    dotOpacity = slideshow.dataset.dotOpacity;
    $('#page-header-slideshow').slick({
      infinite: true,
      autoplay: autoplay,
      speed: speed,
      fade: true,
      pauseOnHover:false,
      cssEase: 'linear',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><span class="material-icons">keyboard_arrow_right</span></button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><span class="material-icons">keyboard_arrow_left</span></button>',
      rows: 0, // fix for extra div https://github.com/kenwheeler/slick/issues/3207
      arrows: arrows,
      dots: true
    });
    if (dotOpacity != "") {
      $('#page-header-slideshow .slick-dots').css('opacity', + dotOpacity / 100);
    }
  }
}